import React from 'react';
import APIService from "../../modules/apiService";

export function withFooter(WrappedComponent) {
	// ...and returns another component...
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				venueName: '',
				venueSupportEmail: ''
			};

			this.api = new APIService();
		}

		componentWillMount() {
			this.api.getConfig().then(res => {
				this.setState({
					venueName: res['venue-name'],
					venueSupportEmail: res['support-email'],
				});
			}).catch(err => {
				console.error(err)
			});
		}

		render() {

			const year = new Date().getFullYear();

			const {venueName, venueSupportEmail} = this.state;

			return (
			  <>
				  <WrappedComponent {...this.props} />
				  <div className="container">
					  <footer className="footer">
						  <p>&copy; 2015 - {year} {venueName} &middot;&nbsp;
							  <span>v{process.env.REACT_APP_VERSION}</span>
							  &nbsp;&middot;&nbsp;
							  {/*<a target="_blank" href={`mailto:${venueSupportEmail}`}>{venueSupportEmail}</a>&nbsp;&middot;*/}
							  &nbsp;
							  <a target="_blank" href="/privacy" data-i18n="footer.privacyLink">Privacidad</a>&nbsp;&middot;&nbsp;
							  <a target="_blank" href="/terms" data-i18n="footer.termsLink">Términos y
								  Condiciones</a> - Powered by <a href="https://eventticket.com.ar" target="_blank">EventTicket</a></p>
					  </footer>
				  </div>
			  </>
			)
		}
	};
}
