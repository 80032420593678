import React, {Component} from 'react';
import Carousel from 'nuka-carousel';
import Button from '../Button';
import Counter from '../Counter';
import DateTimeService from "../../modules/DateTimeService";
import locales_es from '../../locales/es';
import Helpers from "../../modules/helpers";

export default class Banner extends Component {

  constructor(props) {
    super(props);

    this.dateTimeService = new DateTimeService();
    this.helpers = new Helpers();
  }


  render() {
    const {slides = []} = this.props;

    return (
      <Carousel autoplay={true} wrapAround={true}
                defaultControlsConfig={{nextButtonStyle: {display: 'none'}, prevButtonStyle: {display: 'none'}}}>

        {slides.length ?
          slides.map(slide =>
            <section id="featured-event-container"
                     style={{backgroundImage: `url(${this.helpers.isMobile() ? slide.full_image_mobile : slide.full_image_desktop})`}}>
              <div className="event-date">
                {slide.date_text &&
                  <p>{slide.date_text}</p>
                }
                {slide.date &&
                  <span>{this.dateTimeService.parseEventDate(slide.date, null, 'full-string')}</span>
                }
                {/* <div className="arrow-down">
					<img src="img/arrow-down.png" alt="Flecha hacia abajo" />
				</div> */}
              </div>
              {slide.title || slide.subtitle ?
                <div className="event-title">
                  {slide.title &&
                    <h1 className="text-center">
                      {slide.title}
                    </h1>
                  }
                  {slide.subtitle &&
                    <h2 className="text-center">{slide.subtitle}</h2>
                  }
                </div>
                : null}

              {slide.countdown &&
                <Counter targetDate={new Date(slide.countdown).getTime()}/>
              }

              {slide.link &&
                <Button
                  link={() => window.open(slide.link, '_blank')}
                  className="btn-primario"
                  text="VER EVENTO"
                />
              }
            </section>
          ) : null}
      </Carousel>
    );
  }
}