import React, {Component} from 'react';
import {
    mapFullScreenBtnClassName,
    mapZoomInBtnClassName,
    mapZoomOutBtnClassName,
    MAX_ZOOM_MOBILE,
    AREA_DISABLED_ATTR,
    AREA_FINAL_PRICE_ATTR,
    AREA_NAME_ATTR,
    seatSelectorId,
    AREA_ID_ATTR, AREA_SEAT_TYPE_ATTR, AREA_FULL_REFERENCE_IMAGE_ATTR,
} from "../../models/constants";
import locales_es from "../../locales/es";
import {eventsHandler} from "../../modules/mobileMapEventsHandler";
import svgPanZoom from "svg-pan-zoom";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import DateTimeService from "../../modules/DateTimeService";


export default class AreasMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            setSvgLoading: false,
        };

        this.api = new APIService();
        this.helpers = new Helpers();
        this.dateTimeService = new DateTimeService();
    }

    componentDidMount() {
        this.initSvgMap();
    }

    initSvgMap() {
        const el = document.getElementById(seatSelectorId);
        if (el) {
            el.innerHTML = this.props.areas;
            this.loadSvgMap();
        } else {
            console.error('el not found');
        }
    }

    setSvgLoading(bool) {
        this.setState({
            setSvgLoading: bool
        })
    }

    loadSvgMap() {
        try {
            const options = {
                zoomEnabled: !this.helpers.isMobile(),
                controlIconsEnabled: false,
                fit: true,
                center: true,
                minZoom: 1,
                maxZoom: this.helpers.isMobile() ? MAX_ZOOM_MOBILE : 3,
                dblClickZoomEnabled: false,
                customEventsHandler: this.helpers.isMobile() ? eventsHandler : undefined,
                beforePan: this.helpers.isMobile() ? null : window.beforePan,
                zoomScaleSensitivity: 0.5,
            };
            window.mapZoom = svgPanZoom('#mainSVG2', options);
            this.addAreaListeners();
            this.addMobileMapZoomListeners();
        } catch (e) {
            console.log(e);
            this.props.showMainModal(locales_es.errorModal.title, e.message);
        }
    }

    addMobileMapZoomListeners() {
        const map = window.document.getElementById(seatSelectorId);
        const zoomInBtn = window.document.querySelector(`.${mapZoomInBtnClassName}`);
        const zoomOutBtn = window.document.querySelector(`.${mapZoomOutBtnClassName}`);
        const fullscreenBtn = window.document.querySelector(`.${mapFullScreenBtnClassName}`);
        if (this.helpers.isMobile() && map && zoomInBtn) {
            window.isMobileZoomed = false;

            zoomInBtn.classList.add('show-inline');
            zoomInBtn.addEventListener('click', (e) => {
                if (!window.isMobileZoomed) {
                    e.stopPropagation();
                    window.isMobileZoomed = true;
                    window.mapZoom.zoomBy(MAX_ZOOM_MOBILE);
                    zoomOutBtn.classList.add('show-inline');
                    zoomInBtn.classList.remove('show-inline');
                    fullscreenBtn.classList.add('show-inline');
                }
            }, false);


            zoomOutBtn.addEventListener('click', () => {
                window.mapZoom.zoomBy(0);
                window.isMobileZoomed = false;
                zoomInBtn.classList.add('show-inline');
                zoomOutBtn.classList.remove('show-inline');
                fullscreenBtn.classList.remove('show-inline');
            }, false);

            fullscreenBtn.addEventListener('click', () => {
                map.classList.toggle('eventticket--fullscreen-svg');
                zoomOutBtn.classList.toggle('show-inline');
                // fullscreenBtn.classList.remove('show-inline');
            }, false);
        }
    }

    getAllAreasAsAnArray() {
        const map = window.document.getElementById(seatSelectorId);
        const rects = Array.from(map.getElementsByTagName('rect'));
        const areas = rects.filter(rect => {
            if (!rect.classList.contains('svg-pan-zoom-control-background')) {
                return rect
            }
        })
        return areas;
    }

    addAreaListeners() {
        window.isDragging = false;
        const areas = this.getAllAreasAsAnArray();

        const mobileDelayMove = 300;

        // convert to Array cause areas is an HTML Collection
        areas.map(area => {

            if (!this.helpers.isMobile()) {
                // DESKTOP
                area.addEventListener("mousedown", () => {
                    window.isDragging = false;
                }, false);

                area.addEventListener("mousemove", () => {
                    window.isDragging = true;
                }, false);

                area.addEventListener("mouseup", () => {
                    if (!window.isDragging) {
                        this.onAreaSelect(area);
                    }
                }, false);

            } else {
                // MOBILE
                area.addEventListener("touchstart", () => {
                    window.isDragging = false;
                    window.draggingLapse = new Date();
                }, false);

                area.addEventListener("touchmove", () => {
                    if ((new Date() - window.draggingLapse) > mobileDelayMove) {
                        window.isDragging = true;
                    }
                }, false);

                area.addEventListener("touchend", (e) => {
                    e.preventDefault(); // Woopi: problema que hacía que no se disparara bien el modal de error
                    if (!window.isDragging) {
                        this.onAreaSelect(area);
                    }
                }, false);
            }

            // TOOLTIP
            let areaStr = locales_es.area
                + ': '
                + area.getAttribute(AREA_NAME_ATTR);

            if (area.getAttribute(AREA_FINAL_PRICE_ATTR)) {
                areaStr = ' - '
                    + locales_es.price
                    + ': '
                    + locales_es.currency_sign
                    + area.getAttribute(AREA_FINAL_PRICE_ATTR);
            }
            area.setAttribute('data-toggle', 'tooltip');
            area.setAttribute('data-placement', 'top');
            area.setAttribute('title', areaStr);
        });
        window.$('[data-toggle="tooltip"]').tooltip({'container': 'body',})
    }

    onAreaSelect(area) {
        if (area.getAttribute(AREA_DISABLED_ATTR) === 'true') {
            this.props.showMainModal(locales_es.infoModal.title, locales_es.infoModal.disabledArea);
            return;
        }
        const areaObj = {
            id: area.getAttribute(AREA_ID_ATTR),
            name: area.getAttribute(AREA_NAME_ATTR),
            seatType: area.getAttribute(AREA_SEAT_TYPE_ATTR),
            fullReferenceImage: area.getAttribute(AREA_FULL_REFERENCE_IMAGE_ATTR),
        };
        window.$('[data-toggle="tooltip"], .tooltip').tooltip("hide");
        this.props.onAreaSelect(areaObj);
    }

    render() {
        return (
            <>
                <div className="eventticket-cho-map">
                    <div id={seatSelectorId} style={{overflow: 'hidden'}}/>
                </div>
                <div className="eventticket-cho-map-buttons">
                    <div id="zoom-in" onClick={() =>{window.mapZoom.zoomIn()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
                    </div>
                    <div id="reset" onClick={() =>{window.mapZoom.reset()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M128 384H352V448H128C92.65 448 64 419.3 64 384V128H32C14.33 128 0 113.7 0 96C0 78.33 14.33 64 32 64H64V32C64 14.33 78.33 0 96 0C113.7 0 128 14.33 128 32V384zM384 128H160V64H384C419.3 64 448 92.65 448 128V384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H448V480C448 497.7 433.7 512 416 512C398.3 512 384 497.7 384 480V128z"/></svg>
                    </div>
                    <div id="zoom-out" onClick={() =>{window.mapZoom.zoomOut()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"/></svg>
                    </div>
                </div>
            </>
        )
    }
}
