import React, {Component} from 'react';
import {
  mapFullScreenBtnClassName,
  mapZoomInBtnClassName,
  mapZoomOutBtnClassName,
  MAX_ZOOM_MOBILE,
  SEAT_DISABLED_ATTR,
  SEAT_FILTERED_ATTR,
  SEAT_FINAL_PRICE_ATTR, SEAT_ID_ATTR,
  SEAT_NAME_ATTR, SEAT_PRICE_ATTR, SEAT_SECTOR_ID_ATTR, SEAT_SECTOR_NAME_ATTR,
  SEAT_SELECTION_CLASSNAME, SEAT_SERVICE_CHARGE,
  seatSelectorId, SECTOR_TYPE_NUMBERED
} from "../../models/constants";
import locales_es from "../../locales/es";
import {eventsHandler} from "../../modules/mobileMapEventsHandler";
import svgPanZoom from "svg-pan-zoom";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import CheckoutReview from "../checkoutReview";
import DateTimeService from "../../modules/DateTimeService";
import Spinner from "../spinner";


export default class NumberedMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      svgLoading: true,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
  }

  componentDidMount() {
    this.getSvgMap();
  }

  getSvgMap() {
    this.setSvgLoading(true);
    if (this.props.area.id && this.props.performanceId) {
      this.api.getAreaMapAsJSON(this.props.area.id, this.props.performanceId).then(res => {
        this.setSvgLoading(false);
        const el = document.getElementById(seatSelectorId);
        if (el) {
          el.innerHTML = res.data;
          this.loadSvgMap();
        } else {
          console.error('el not found');
        }
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setSvgLoading(false);
      });
    }
  }

  setSvgLoading(bool) {
    this.setState({
      svgLoading: bool
    })
  }

  loadSvgMap() {
    try {
      const options = {
        zoomEnabled: !this.helpers.isMobile(),
        controlIconsEnabled: false,
        fit: true,
        center: true,
        minZoom: 1,
        maxZoom: this.helpers.isMobile() ? MAX_ZOOM_MOBILE : 3,
        dblClickZoomEnabled: false,
        customEventsHandler: this.helpers.isMobile() ? eventsHandler : undefined,
        beforePan: this.helpers.isMobile() ? null : window.beforePan,
        zoomScaleSensitivity: 0.5,
      };
      window.mapZoom = svgPanZoom('#mainSVG2', options);
      this.addSeatListeners();
      this.addMobileMapZoomListeners();
      this.clear();
    } catch (e) {
      console.log(e);
      this.props.showMainModal(locales_es.errorModal.title, e.message);
    }
  }

  addMobileMapZoomListeners() {
    const map = window.document.getElementById(seatSelectorId);
    const zoomInBtn = window.document.querySelector(`.${mapZoomInBtnClassName}`);
    const zoomOutBtn = window.document.querySelector(`.${mapZoomOutBtnClassName}`);
    const fullscreenBtn = window.document.querySelector(`.${mapFullScreenBtnClassName}`);
    if (this.helpers.isMobile() && map && zoomInBtn) {
      window.isMobileZoomed = false;

      zoomInBtn.classList.add('show-inline');
      zoomInBtn.addEventListener('click', (e) => {
        if (!window.isMobileZoomed) {
          e.stopPropagation();
          window.isMobileZoomed = true;
          window.mapZoom.zoomBy(MAX_ZOOM_MOBILE);
          zoomOutBtn.classList.add('show-inline');
          zoomInBtn.classList.remove('show-inline');
          fullscreenBtn.classList.add('show-inline');
        }
      }, false);


      zoomOutBtn.addEventListener('click', () => {
        window.mapZoom.zoomBy(0);
        window.isMobileZoomed = false;
        zoomInBtn.classList.add('show-inline');
        zoomOutBtn.classList.remove('show-inline');
        fullscreenBtn.classList.remove('show-inline');
      }, false);

      fullscreenBtn.addEventListener('click', () => {
        map.classList.toggle('eventticket--fullscreen-svg');
        zoomOutBtn.classList.toggle('show-inline');
        // fullscreenBtn.classList.remove('show-inline');
      }, false);
    }
  }

  getAllSeatsAsAnArray() {
    const map = window.document.getElementById(seatSelectorId);
    const seats = map.getElementsByTagName('circle');
    return Array.from(seats);
  }

  addSeatListeners() {
    window.isDragging = false;
    const seats = this.getAllSeatsAsAnArray();

    const mobileDelayMove = 300;

    // convert to Array cause seats is an HTML Collection
    seats.map(seat => {

      if (!this.helpers.isMobile()) {
        // DESKTOP
        seat.addEventListener("mousedown", () => {
          window.isDragging = false;
        }, false);

        seat.addEventListener("mousemove", () => {
          window.isDragging = true;
        }, false);

        seat.addEventListener("mouseup", () => {
          if (!window.isDragging) {
            this.onSeatSelect(seat);
          }
        }, false);

      } else {
        // MOBILE
        seat.addEventListener("touchstart", () => {
          window.isDragging = false;
          window.draggingLapse = new Date();
        }, false);

        seat.addEventListener("touchmove", () => {
          if ((new Date() - window.draggingLapse) > mobileDelayMove) {
            window.isDragging = true;
          }
        }, false);

        seat.addEventListener("touchend", (e) => {
          e.preventDefault(); // Woopi: problema que hacía que no se disparar bien el modal de error
          if (!window.isDragging) {
            this.onSeatSelect(seat);
          }
        }, false);
      }

      // TOOLTIP
      let seatStr = locales_es.seat
        + ': '
        + seat.getAttribute(SEAT_NAME_ATTR);

      if (seat.getAttribute(SEAT_FINAL_PRICE_ATTR)) {
        seatStr += ' - '
          + locales_es.price
          + ': '
          + locales_es.currency_sign
          + seat.getAttribute(SEAT_FINAL_PRICE_ATTR);
      }
      seat.setAttribute('data-toggle', 'tooltip');
      seat.setAttribute('data-placement', 'top');
      seat.setAttribute('title', seatStr);
    });
    window.$('[data-toggle="tooltip"]').tooltip({'container': 'body',})
  }

  onSeatSelect(seat) {
    // console.log(seat);
    if (seat.getAttribute(SEAT_DISABLED_ATTR) === 'true') {
      this.props.showMainModal(locales_es.infoModal.title, locales_es.infoModal.disabledSeat);
      return;
    }
    if (seat.getAttribute(SEAT_FILTERED_ATTR) === 'true') {
      this.props.showMainModal(locales_es.infoModal.title, locales_es.infoModal.filteredSeat);
      return;
    }
    seat.classList.toggle(SEAT_SELECTION_CLASSNAME);
    this.filterAvailableSeatsBySector(seat);

    // TEST PHASE...
    this.syncStateWithFrontendView().catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err))
    });
  }

  filterAvailableSeatsBySector(seat) {
    const seats = this.getAllSeatsAsAnArray();
    // Si el asiento quedó elegido
    if (seat.classList.contains(SEAT_SELECTION_CLASSNAME)) {
      seats.map(s => {
        if (s.getAttribute(SEAT_SECTOR_ID_ATTR) !== seat.getAttribute(SEAT_SECTOR_ID_ATTR)) {
          s.setAttribute(SEAT_FILTERED_ATTR, 'true');
        }
      });
      // this.toggleChoCartReviewOrSet(true);
    } else {
      if (!this.hasSelectedSeats() && this.hasFilteredSeats()) {
        this.clearFilteredSeats();
      }
    }
  }

  clearFilteredSeats() {
    const seats = this.getAllSeatsAsAnArray();
    seats.map(s => {
      s.removeAttribute(SEAT_FILTERED_ATTR);
    });
  }

  clearSelectedSeats() {
    const seats = this.getAllSeatsAsAnArray();
    seats.map(s => {
      s.classList.remove(SEAT_SELECTION_CLASSNAME);
    });
  }

  clearSeatsAndSectorState() {
    this.setState({
      areaId: '',
      seatsIds: '',
    });
  }

  hasFilteredSeats() {
    const seats = this.getAllSeatsAsAnArray();
    return seats.some(seat => seat.getAttribute(SEAT_FILTERED_ATTR) === 'true');
  }

  hasSelectedSeats() {
    const seats = this.getAllSeatsAsAnArray();
    return seats.some(seat => seat.classList.contains(SEAT_SELECTION_CLASSNAME));
  }

  async syncStateWithFrontendView() {
    const seats = this.getAllSeatsAsAnArray();
    const selectedSeats = [];

    seats.map(s => {
      if (s.classList.contains(SEAT_SELECTION_CLASSNAME)) {
        // if (!this.state.areaId) {
        this.setState({
          sectorId: s.getAttribute(SEAT_SECTOR_ID_ATTR),
          sectorPrice: s.getAttribute(SEAT_PRICE_ATTR),
          serviceCharge: s.getAttribute(SEAT_SERVICE_CHARGE),
          sectorName: s.getAttribute(SEAT_SECTOR_NAME_ATTR),
        }, this.setSelectedSectorForReviewRenderingOnly);
        // }

        // SEAT
        const seatObj = {
          id: s.getAttribute(SEAT_ID_ATTR),
          name: s.getAttribute(SEAT_NAME_ATTR),
          price: s.getAttribute(SEAT_PRICE_ATTR),
          serviceCharge: s.getAttribute(SEAT_SERVICE_CHARGE),
          finalPrice: s.getAttribute(SEAT_FINAL_PRICE_ATTR),
        };
        selectedSeats.push(seatObj);
      }
    });

    console.log(selectedSeats);

    await this.setState({
      seatsIds: selectedSeats
    });
  }

  clear() {
    this.clearFilteredSeats();
    this.clearSelectedSeats();
    this.clearSeatsAndSectorState();
  }

  toggleChoCartReviewOrSet(bool) {
    this.setState({
      choCartReviewVisible: bool || !this.state.choCartReviewVisible
    })
  }

  confirm() {
    if (this.state.seatsIds && this.state.seatsIds.length) {
      const totalPrice =
        (this.state.seatsIds.length * this.state.sectorPrice) + (this.state.seatsIds.length * this.state.serviceCharge);
      this.props.confirm(this.state.seatsIds, totalPrice, this.state.sectorId, this.state.sectorPrice, this.state.serviceCharge, this.state.sectorName);
    } else {
      this.props.showMainModal(locales_es.infoModal.title, 'Elija al menos un asiento del mapa para continuar');
    }
  }

  render() {

    const {
      seatsIds,
      sectorPrice,
      serviceCharge,
      sectorName,
    } = this.state;

    const {
      area,
      selectedPerformanceForReviewRenderingOnly,
    } = this.props;

    return (
      <>
        {this.props.goBack && !this.state.svgLoading ?
          <a className="cursor-pointer" onClick={() => this.props.goBack()}>{locales_es.back}</a>
          : null}
        <div className="eventticket-cho-map">
          <div id={seatSelectorId} style={{overflow: 'hidden'}}/>
        </div>
        <div className="eventticket-cho-map-buttons">
          <div id="zoom-in" onClick={() =>{window.mapZoom.zoomIn()}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
          </div>
          <div id="reset" onClick={() =>{window.mapZoom.reset()}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M128 384H352V448H128C92.65 448 64 419.3 64 384V128H32C14.33 128 0 113.7 0 96C0 78.33 14.33 64 32 64H64V32C64 14.33 78.33 0 96 0C113.7 0 128 14.33 128 32V384zM384 128H160V64H384C419.3 64 448 92.65 448 128V384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H448V480C448 497.7 433.7 512 416 512C398.3 512 384 497.7 384 480V128z"/></svg>
          </div>
          <div id="zoom-out" onClick={() =>{window.mapZoom.zoomOut()}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"/></svg>
          </div>
        </div>

        {this.state.svgLoading ? <Spinner/> : <CheckoutReview
          seatsIds={seatsIds}
          choCartReviewVisible={this.state.choCartReviewVisible}
          sectorPrice={sectorPrice}
          serviceCharge={serviceCharge}
          date={selectedPerformanceForReviewRenderingOnly
            ? this.dateTimeService.parseEventDate(selectedPerformanceForReviewRenderingOnly.date, false, 'full-string') + ' ' + this.dateTimeService.parseEventTime(selectedPerformanceForReviewRenderingOnly.date, 'full-string')
            : ''}
          areaName={area ? area.name : ''}
          sectorName={sectorName ? sectorName : ''}
          clear={() => this.clear()}
          send={() => this.confirm()}
          toggleChoCartReviewOrSet={() => this.toggleChoCartReviewOrSet()}
        />
        }
      </>
    )
  }
}
