const getApiURL = () => {
    let i = 0, domain = document.domain, p = domain.split('.'), s = '_gd' + (new Date()).getTime();
    while (i < (p.length - 1) && document.cookie.indexOf(s + '=' + s) == -1) {
        domain = p.slice(-1 - (++i)).join('.');
        document.cookie = s + "=" + s + ";domain=" + domain + ";";
    }
    document.cookie = s + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + domain + ";";

    // 'localhost' or 'a digit'
    const localhostRegex = /localhost|192\.168\.0\.7/gm;
    if (localhostRegex.test(window.location.href)) {
        if (process.env.REACT_APP_ENV === 'dev') {
            console.warn('AMBIENTE DE PRUEBA');
            return process.env.REACT_APP_DEV_API_ENDPOINT;
        }
        if (process.env.REACT_APP_ENV === 'prod') {
            console.warn('AMBIENTE DE PRUEBA a PROD');
            return process.env.REACT_APP_PROD_API_ENDPOINT;
        }
    }

    return window.location.protocol + '//api.' + domain;
};

// let apiURL = process.env.REACT_APP_PROD_API_ENDPOINT;
let apiURL = getApiURL();

// DEV
if (process.env.REACT_APP_ENV === 'dev') {
    apiURL = process.env.REACT_APP_DEV_API_ENDPOINT;
}

// MOCK
if (process.env.REACT_APP_ENV === 'mock') {
    apiURL = 'http://localhost:9001';
}

// NGROK
if (process.env.REACT_APP_ENV === 'ngrok') {
    // apiURL = 'http://9dc1e64a.ngrok.io/api/';
}

// LOCAL
if (process.env.REACT_APP_ENV === 'local') {
    apiURL = '';
}


const apiVersion = '/v1';
apiURL += apiVersion;


export const apiURL_config = apiURL + '/configs';

export const apiURL_coupons = apiURL + '/coupons';
export const apiURL_coupons_available = apiURL + '/coupons_available';

export const apiURL_auth = apiURL + '/auth';
export const apiURL_login = apiURL_auth + '/login';
export const apiURL_register = apiURL_auth + '/register';
export const apiURL_checkout = apiURL + '/checkout';
export const apiURL_checkout_stripe = apiURL_checkout + '/stripe';

// Events
export const apiURL_banners = apiURL + '/banners';
export const apiURL_events = apiURL + '/events';
export const apiURL_maps_performance = apiURL + '/maps/performance';
export const apiURL_maps_areas = apiURL + '/maps/areas';
export const apiURL_performances = apiURL + '/performances';
export const apiURL_performances_event = apiURL_performances + '/event';
export const apiURL_areas = apiURL + '/areas';
export const apiURL_premaps = apiURL + '/premaps';
export const apiURL_sectors = apiURL + '/sectors';
export const apiURL_seats = apiURL + '/seats';
export const apiURL_tickets = apiURL + '/tickets';
export const apiURL_tickets_me = apiURL_tickets + '/me';
export const apiURL_carts = apiURL + '/carts';
export const apiURL_carts_me = apiURL_carts + '/me';

// Users
export const apiURL_users = apiURL + '/users';
export const apiURL_user_me = apiURL_users + '/me';
export const apiURL_password_request = apiURL_auth + '/password_request';
export const apiURL_user_password = apiURL_users + '/password';
