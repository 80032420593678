export const analyticsConfig = [
    {
        id: 1,
        event_id: 5,
        facebook: {
            pixel: '563287171720774',
            pageViews: ['/event/5'],
        },
        google: {
            id: 'AW-10900071978',
            events: {
                // loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                // loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                // registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                confirm: 'AW-10900071978/BoMYCPTR6bwDEKrMx80o',
            }
        }
    },
    {
        id: 2,
        event_id: 4,
        facebook: {
            pixel: '563287171720774',
            pageViews: ['/event/4'],
        },
        google: {
            id: 'AW-10900071978',
            events: {
                // loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                // loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                // registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                confirm: 'AW-10900071978/BoMYCPTR6bwDEKrMx80o',
            }
        }
    },
    {
        id: 3,
        event_id: 6,
        facebook: {
            pixel: '563287171720774',
            pageViews: ['/event/6'],
        },
        google: {
            id: 'AW-10900071978',
            events: {
                // loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                // loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                // registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                confirm: 'AW-10900071978/BoMYCPTR6bwDEKrMx80o',
            }
        }
    },
    {
        id: 4,
        event_id: 7,
        facebook: {
            pixel: '563287171720774',
            pageViews: ['/event/7'],
        },
        google: {
            id: 'AW-10900071978',
            events: {
                // loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                // loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                // registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                confirm: 'AW-10900071978/BoMYCPTR6bwDEKrMx80o',
            }
        }
    },
    {
        id: 5,
        event_id: 12,
        google: {
            id: 'AW-10985604617',
            events: {
                // loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                // loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                // registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                // confirm: 'AW-10900071978/BoMYCPTR6bwDEKrMx80o',
            }
        }
    },
    {
        id: 6,
        event_id: 17,
        google: {
            id: 'AW-10900071978',
            events: {
                // loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                // loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                // registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                confirm: 'AW-10900071978/BoMYCPTR6bwDEKrMx80o',
            }
        }
    },
    {
        id: 7,
        event_id: 23,
        google: {
            id: 'AW-10900071978',
            events: {
                // loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                // loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                // registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                confirm: 'AW-10900071978/BoMYCPTR6bwDEKrMx80o',
            }
        }
    },
];
