import {
	apiURL_login,
	apiURL_register,
	apiURL_users,
	apiURL_user_me,
	apiURL_password_request,
	apiURL_user_password,
	apiURL_medics,
	apiURL_events,
	apiURL_shows,
	apiURL_performances,
	apiURL_performances_event,
	apiURL_sectors,
	apiURL_seats,
	apiURL_checkout,
	apiURL_tickets_me,
	apiURL_checkout_stripe,
	apiURL_config,
	apiURL_maps_performance,
	apiURL_coupons,
	apiURL_coupons_available,
	apiURL_areas,
	apiURL_maps_areas,
	apiURL_premaps, apiURL_carts_me, apiURL_banners,
}
	from "../models/api-urls";

import unregister from './apiService.config'; // No estamos usando el register(), pero importarlo hace que funcione el interceptor

import AuthService from './authService';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export const configDataLocalStorage = 'config_data';
const configDataExpireDateLocalStorage = 'config_data_expire_date';

class APIService {


	getConfig = async() => {
		return new Promise((resolve, reject) => {
			try {

				const ls = JSON.parse(localStorage.getItem(configDataLocalStorage));

				// TODO agregar un expire de 24hs. a esto...
				const now = new Date();
				// const ttl = 86400000;// seconds (24 hours)
				// const ttl = 8640000;// más o menos 3 hrs.
				const ttl = 3600000; // ms = 1 hr
				const lsTTL = JSON.parse(localStorage.getItem(configDataExpireDateLocalStorage));

				if (ls) {
					// console.log(now.toLocaleString());
					// console.log(new Date(lsTTL).toLocaleString());
					if(lsTTL && (now.getTime() < lsTTL)) {
						// console.log('not refreshing');
						resolve(ls);
					} else {
						// console.log('refreshing...');
						this.setClinicDataExpireDate(now, ttl);
						this.getRemoteConfigData()
							.then(res => resolve(res))
							.catch(err => reject(err));
					}
				} else {
					this.setClinicDataExpireDate(now, ttl);
					this.getRemoteConfigData()
						.then(res => resolve(res))
						.catch(err => reject(err));
				}
			} catch(err) {
				console.info(err);
				this.clearLocalConfigData();
			}
		});
	};

	setClinicDataExpireDate(date, ttl) {
		localStorage.setItem(configDataExpireDateLocalStorage, (date.getTime() + ttl));
	}

	clearLocalConfigData() {
		localStorage.removeItem(configDataLocalStorage);
	}

	getRemoteConfig = async () => {
		const response = await fetch(apiURL_config, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
        this.callAuthServiceLogout();
        return false;
    }*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	async getRemoteConfigData() {
		return new Promise((resolve, reject) => {
			this.getRemoteConfig().then(res => {
				const configData = res.data;
				localStorage.setItem(configDataLocalStorage, JSON.stringify(configData || {}));
				resolve(configData);
			}).catch(err => {
				// this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
				reject(err);
			});
		});
	}

	setParams = (urlStr, params) => {
		const url = new URL(urlStr);
		  // params = {lat:35.696233, long:139.570431};
		if(params) {
			Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
		}
		return url;
	};

	getCoupon = async(eventId, coupon) => {
		const response = await fetch(apiURL_coupons + '/' + eventId + '/' + coupon, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	checkCoupons = async(eventId) => {
		const response = await fetch(apiURL_coupons_available + '/' + eventId, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

    login = async(email, password) => {
        const response = await fetch(apiURL_login, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                email,
                password,
            })
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.message);

        return body;
    };

    register = async(data) => {
        const response = await fetch(apiURL_register, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.errors && body.errors.length ? body.errors[0] : body.message);

        return body;
    };

    updateUser = async(data) => {
        const response = await fetch(apiURL_user_me, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.message);

        return body;
    };

    passwordRequest = async(email) => {
        const response = await fetch(apiURL_password_request, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                email,
            })
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.message);

        return body;
    };

	updatePassword = async(user) => {
		const response = await fetch(apiURL_user_password, {
			method: 'PUT',
			headers,
			body: JSON.stringify(user)
		});
		const body = await response.json();

		if (response.status >= 300) throw Error(body.message);

		return body;
	};

    getUserMe = async() => {
        const response = await fetch(apiURL_user_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
        if (response.status >= 300) throw Error(body.message);

        return body;
    };

	getBanners = async(preview) => {
		let url = apiURL_banners;
		if (preview) {
			url += "/preview";
		}
		const response = await fetch(url, {
				method: 'GET',
				headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
				this.callAuthServiceLogout();
				return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getEvents = async(eventId) => {
		let url = apiURL_events;
		if(eventId) {
			url += '/' + eventId
		}
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
        if (response.status >= 300) throw Error(body.message);

        return body;
    };

	getEventByNamespace = async(namespace) => {
		let url = apiURL_events + '/namespace/' + namespace;
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
        if (response.status >= 300) throw Error(body.message);

        return body;
    };

	getPerformance = async(performanceId) => {
		let url = apiURL_performances + '/' + performanceId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getPerformances = async(eventId) => {
		let url = apiURL_performances_event + '/' + eventId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getAreas = async(performanceId) => {
		let url = apiURL_areas + '/performance/' + performanceId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getAreasAsSVG = async(performanceId) => {
		// TODO mock for testing purposes till API implements this call
		/*return new Promise((resolve, reject) => {
			resolve({
				"status":true,
				"data":"<svg version=\"1.1\" id=\"mainSVG2\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 1185 909\" enable-background=\"new 0 0 1185 909\" xml:space=\"preserve\">\n<g id=\"eVR5Bs_1_\">\n\t<image overflow=\"visible\" width=\"1185\" height=\"909\" id=\"eVR5Bs_2_\" xlink:href=\"http:\/\/localhost:3000\/476DBFC41219C4D.jpg\" >\n\t</image>\n</g>\n<rect seattype=\"numbered\" areaid=\"3\" areafinalprice=\"9000\" areaname=\"Campo Platinum\" x=\"356.5\" y=\"161.2\" opacity=\"0.5\" fill=\"#3217FF\" width=\"80\" height=\"149\"/>\n<rect seattype=\"numbered\" areaid=\"3\" areafinalprice=\"9000\" areaname=\"Campo Platinum\" x=\"443.5\" y=\"161.2\" opacity=\"0.5\" fill=\"#3217FF\" width=\"80\" height=\"149\"/>\n<rect seattype=\"numbered\" areaid=\"4\" areafinalprice=\"9000\" areaname=\"Campo Platinum\" x=\"619.5\" y=\"161.2\" opacity=\"0.5\" fill=\"#3217FF\" width=\"80\" height=\"149\"/>\n<rect seattype=\"numbered\" areaid=\"4\" areadisabled=\"true\" areafinalprice=\"9000\" areaname=\"Campo Platinum\" x=\"707.5\" y=\"161.2\" opacity=\"0.5\" fill=\"#000\" width=\"80\" height=\"149\"/>\n<rect seattype=\"quantity\" areaid=\"5\" areafinalprice=\"10000\" areaname=\"Campo Platinum VIP\" x=\"531.5\" y=\"161.2\" opacity=\"0.5\" fill=\"#FF5CDF\" width=\"80\" height=\"149\"/>\n</svg>\n",
				"message":"SVG del mapa"
			})
		});*/

		let url = apiURL_premaps + '/performance/' + performanceId + '/format/json';
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getSectors = async(eventId, performanceId, areaId) => {
		let url = apiURL_sectors + '?event_id=' + eventId + '&performance_id=' + performanceId;
		if (areaId) {
			url += '&area_id=' + areaId;
		}
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getSeats = async(performanceId, sectorId) => {
		let url = apiURL_seats + '?performance_id=' + performanceId + '&seat_sector_id=' + sectorId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getPerformanceMapAsJSON = async(performanceId) => {
		let url = apiURL_maps_performance + '/' + performanceId + '/format/json';
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getAreaMapAsJSON = async(areaId, performanceId) => {
		let url = apiURL_maps_areas + '/' + areaId + '/performance/' + performanceId + '/format/json';
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	postCheckout = async(data) => {
		const response = await fetch(apiURL_checkout, {
			method: 'POST',
			headers,
			body: JSON.stringify(data)
		});
		const body = await response.json();

		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	postCheckoutStripe = async(data) => {
		const response = await fetch(apiURL_checkout_stripe, {
			method: 'POST',
			headers,
			body: JSON.stringify(data)
		});
		const body = await response.json();

		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getMyTickets = async() => {
		const response = await fetch(apiURL_tickets_me, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getMyCarts = async() => {
		const response = await fetch(apiURL_carts_me, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

}

//unregister();

export default APIService;
