import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({path, className, text, link}) => {

	return (
		<div className="btn-container">
			{path ? 
			<Link to={path} className={className} >
				{text}
			</Link>			
			:
			<button onClick={link} className={className} >
				{text}
			</button>
			}
		</div>
	);
};

export default Button;
