import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import {withFooter} from "../../components/footerHOC";
import APIService from './../../modules/apiService';
import Banner from "../../components/Banner";

class BannerPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      events: null,
      banners: [],
    };

    this.api = new APIService();
  }

  componentDidMount() {
    this.getBanners();
  }

  getBanners() {
    this.api.getBanners(true).then(res => {
      if (res && res.data && res.data.length) {
        this.setState({
          banners: res.data,
        })
      }
    }).catch(err => {
      this.props.showMainModal('Error', err.message);
    })
  }

  render() {

    return (
      <div>
        {this.state.loading ? <Loading/> : ''}
        <Header showMainModal={this.props.showMainModal}/>

        {this.state.banners.length ?
          <Banner slides={this.state.banners}/>
          : null}
      </div>
    )
  }
}

export default withFooter(BannerPreview);
