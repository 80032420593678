import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import {withFooter} from "../../components/footerHOC";
import APIService from "../../modules/apiService";
import DateTimeService from "../../modules/DateTimeService";
import AuthService from "../../modules/authService";
import locales_es from "../../locales/es";
import {hrefHome} from "../../models/constants";

class DashboardPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			carts: null,
			loading: false,
			cartDetail: null
		};

		this.api = new APIService();
		this.dateTimeService = new DateTimeService();
		this.auth = new AuthService();
	}

	componentDidMount() {
		if (!this.auth.isLoggedUser()) {
			window.location.href = '/';
			return;
		}
		this.getMyCarts();
	}

	getMyCarts() {
		this.api.getMyCarts().then(res => {
			this.setState({
				carts: res.data
			});
		}).catch(err => {
			this.props.showMainModal('Error', err.message);
		})
	}

	renderCarts() {
		return (
		  <tbody>
		  {this.state.carts.map(cart => {
			  return (
				<tr key={cart.code}>
					<td><a onClick={() => window.open(cart.full_pdf_url)} className="btn btn-sm btn-warning">E-Tickets</a></td>
					<td>{cart.event_performance && cart.event_performance.event ? cart.event_performance.event.title : ''}</td>
					<td>
						{cart.event_performance ?
							<>
								<span dangerouslySetInnerHTML={
									{
										__html: this.dateTimeService.parseEventDate(cart.event_performance.date)
									}
								}/>&nbsp;
								<span dangerouslySetInnerHTML={
									{
										__html: this.dateTimeService.parseEventTime(cart.event_performance.date)
									}
								}/>
							</>
						: null}
					</td>
					<td>${cart.price}</td>
					<td>
						<span dangerouslySetInnerHTML={
							{
								__html: this.dateTimeService.parseEventDate(cart.created_at, true, 'simple-string')
							}
						}/>&nbsp;
						<span dangerouslySetInnerHTML={
							{
								__html: this.dateTimeService.parseEventTime(cart.created_at)
							}
						}/>
					</td>
				</tr>
			  )
		  })}
		  </tbody>
		)
	}

	render() {

		const {carts, cartDetail} = this.state;

		return (
		  <div>
			  {this.state.loading ? <Loading/> : ''}

			  <Header showMainModal={this.props.showMainModal}/>

			  <div className="row margin-top-xl padding-top-lg">
				  <div className="container">
					  <h2 className="sub-header" data-i18n="dashboard.title">Mis Compras</h2>

					  {carts === null ? <Loading/>
						: carts && carts.length ?
						  <div className="table-responsive">
							  <table data-test="events-list" className="table table-striped tickets-list-table">
								  <thead>
								  <tr>
									  <th data-i18n="dashboard.thead.actions">Acciones</th>
									  <th data-i18n="dashboard.thead.event">Evento</th>
									  <th data-i18n="dashboard.thead.ticket">Función</th>
									  <th data-i18n="dashboard.thead.price">Precio</th>
									  <th data-i18n="dashboard.thead.purchaseDate">Fecha de Compra</th>
								  </tr>
								  </thead>
								  {this.renderCarts()}
							  </table>
						  </div>
						  :
						  <div className="row tcenter dashboard__list-events___request-status">
							  <span
							    data-i18n="dashboard.noTicketsMessage">No has comprado ningún ticket todavía...</span>
							  <br/><br/>
							  <div className="col-xs-12">
								  <a onClick={() => this.props.history.push(hrefHome)} type="button"
										  className="btn btn-primary">
									  {locales_es.buyTickets}
								  </a>
							  </div>
						  </div>
					  }
				  </div>
			  </div>


			  {cartDetail ?
				<div id="myModal" className="modal fade" tabIndex="-1" role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-body tcenter">
								<h3>{cartDetail.event && cartDetail.event.title ? cartDetail.event.title : ''}</h3>
								<h4>Función: <span dangerouslySetInnerHTML={
									{
										__html: this.dateTimeService.parseEventDate(cartDetail.performance.date)
									}
								}/>
									<span dangerouslySetInnerHTML={
										{
											__html: this.dateTimeService.parseEventTime(cartDetail.performance.date)
										}
									}/>
								</h4>
								<p>
									<img src={cartDetail.full_image}/>
								</p>
								<p>
									<strong>{cartDetail.code}</strong>
								</p>
								<p>
									<strong>Sector: {cartDetail.sector.name}</strong>
								</p>
								<p>
									<strong>Asiento: {cartDetail.seat.name}</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
				: null}


		  </div>
		)
	}
}

export default withFooter(DashboardPage);
